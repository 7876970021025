import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
// eslint-disable-next-line import/no-cycle
import AuthModule from './auth/index';
import CategoryModule from './category/index';
import FavoriteModule from './favorite/index';
import FileModule from './file/index';
import LocaleModule from './locale/index';
import NotificationModule from './notification/index';
import SearchModule from './search/index';
import SortModule from './sort/index';
import SyncModule from './sync/index';

Vue.use(Vuex);

const persistedState = createPersistedState({
  paths: ['auth.username', 'auth.password', 'locale.currentId'],
});

export default new Vuex.Store({
  modules: {
    auth: AuthModule,
    notification: NotificationModule,
    locale: LocaleModule,
    file: FileModule,
    search: SearchModule,
    category: CategoryModule,
    favorite: FavoriteModule,
    sync: SyncModule,
    sort: SortModule,
  },
  plugins: [persistedState],
});
